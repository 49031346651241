<template>

  <div>

    <vs-table :data="financialReportsData"
              :pagination="financialReportsData.length > maxItems ? true : false" :max-items="maxItems">
      <template slot="header">

        <div class="d-flex mi-align-horizontal-left">
          <div class="d-inline-block float-left">
            <h2>Gotovina
            </h2>
          </div>
          <vs-chip class="mt-1 ml-3">{{ financialReportsData.length }}</vs-chip>
        </div>

      </template>
      <template slot="thead">
        <vs-th sort-key="id">
          <!-- empty -->
        </vs-th>
        <vs-th sort-key="donationDate">
          Datum
        </vs-th>
        <vs-th sort-key="direction">
          + priliv / - odliv
        </vs-th>
        <vs-th sort-key="amount">
          Znesek
        </vs-th>
        <vs-th sort-key="unit_id">
          Enota
        </vs-th>
        <vs-th sory-key="description">
          Opis
        </vs-th>
        <vs-th sory-key="id" style="width: 200px;">
          Akcije
        </vs-th>

      </template>

      <template slot-scope="{data}">
        <vs-tr :key="data[index].id" v-for="(tr, index) in data" :data="tr">
          <vs-td :data="index">{{ index + 1 }}</vs-td>
          <vs-td :data="moment(data[index].date)">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ moment(data[index].date).format('DD. MM. YYYY') }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].direction">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <span class="text-danger" v-if="data[index].direction === 'WITHDRAW'">- ODLIV</span>
              <span class="text-success" v-if="data[index].direction === 'DEPOSIT'">+ PRILIV</span>
<!--              {{ data[index].direction === 'DEPOSIT' ? '+ PRILIV' : '- ODLIV' }}-->
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].amount">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ Number(parseFloat(data[index].amount).toFixed(2)).toLocaleString('sl', {minimumFractionDigits: 2}) }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].unit.name">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].unit ? data[index].unit.name : ''}}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].description">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].description }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].id">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

              <vs-button type="filled" color="primary" icon="edit" size="small"
                         class="vs-lg-12 mt-2" @click.stop="editFinancialReport(data[index].id)">Uredi
              </vs-button>
              <vs-button type="filled" color="danger" icon="edit" size="small"
                         class="vs-lg-12 mt-2 ml-2" @click.stop="beforeDeleteFinancialReport(data[index])">Izbiriši
              </vs-button>

            </vs-col>
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>


  </div>
</template>

<script>
import Vue from 'vue';


export default {
  name: 'FinancialReportTable',
  props: {
    financialReportsData: Array,
  },
  components: {},

  data() {
    return {
      maxItems: 500,

    }
  },

  computed: {},

  mounted() {
    const _this = this;

  },

  destroyed() {
    console.log("destroyed");
    const _this = this;

  },

  beforeDestroy() {
    console.log("beforeDestroy");
    const _this = this;


  },

  methods: {
    editFinancialReport(financialReportId) {
      const _this = this;
      _this.$router.push({name: 'FinancialReportEdit', params: {financialReportId: financialReportId}});
    },

    beforeDeleteFinancialReport(finacialReport) {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati zapis z opisom \"' + finacialReport.description + '\" in zneskom ' + finacialReport.amount + ' eur ?',
        message: 'Po potrditvi bo zapis trajno izbrisan.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if(confirm) {
            _this.deleteFinancialReport(finacialReport.id)
          }
        }
      });
    },

    deleteFinancialReport(financialReportId) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.delete(Vue.prototype.$config.api.sopotnikiVPS + 'financial-reporting/' + financialReportId)
        .then((res) => {
          _this.$vs.loading.close();
          _this.$parent.getFinancialReports();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    getFiltered(e) {
      console.log(e) //output the filtered items
    },

    stopTheEvent: (event) => {
      event.stopPropagation();
      event.preventDefault();
    },

  }
}
</script>

