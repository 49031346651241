<template>
  <div id="companiesContainer">

    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-disabled="page" class="disabled-link">Finance<span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Gotovina</li>
    </vs-breadcrumb>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Vpiši novo donacijo" @click.stop="addFinancialReport()"></vs-button>


    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <vs-button color="primary" size="small" class="mt-1 mr-2" icon="keyboard_arrow_left"
                   @click="() => {selectedDate = moment(selectedDate).subtract(1, 'M').toDate(); onDateChange()}"></vs-button>
        <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    @input="onDateChange" v-model="selectedDate"></datepicker>
        <vs-button color="primary" size="small " class="mt-1 ml-2" icon="keyboard_arrow_right"
                   @click="() => {selectedDate = moment(selectedDate).add(1, 'M').toDate(); onDateChange()}"></vs-button>

        <v-select id="unit" :options="unitsData" class="ml-20" @input="onUnitChange" style="min-width: 300px"
                  v-model="unitId" placeholder="Filtriraj zadetke po enoti"
                  :reduce="item => item.id"
                  v-if="$globalFunctions.isAdmin() && unitsData"
                  :clearable="false"
                  label="name">

          <template v-slot:option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:selected-option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
            </template>
            <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
          </template>
        </v-select>


<!--        <download-excel :data="financialReportsData"-->
<!--                        :fields="fields_to_export"-->
<!--                        worksheet="Gotovina"-->
<!--                        :name="moment(selectedDate).year() + '_' + (moment(selectedDate).month()+1) + '_gotovina.xls'"-->
<!--                        class="ml-auto"-->
<!--                        v-if="financialReportsData && financialReportsData.length > 0"-->
<!--                        :footer="xlsFooterData">-->
<!--          <vs-button color="success" icon="description">Izvozi v Excel</vs-button>-->
<!--        </download-excel>-->
        <vs-button class="ml-auto" color="success" icon="description" @click="exportData" v-if="financialReportsData && financialReportsData.length > 0">Izvozi v Excel</vs-button>

      </vs-col>
    </vs-row>

    <div v-if="financialReportsData && financialReportsData.length > 0" class="mt-10">
      <FinancialReportsTable :financial-reports-data="financialReportsData"/>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
          <h4 class="mt-10 mr-10 float-right"><span class="text-normal">Stanje gotovine začetek meseca:</span> <b
            class="text-success text-bold">{{ Number(parseFloat(this.balanceBefore).toFixed(2)).toLocaleString('sl', {minimumFractionDigits: 2}) }} eur</b></h4>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
          <h2 class="mt-10 mr-10 float-right"><span class="text-normal">Stanje gotovine konec meseca:</span> <b
            class="text-success text-bold">{{ Number(parseFloat(this.balanceAfter).toFixed(2)).toLocaleString('sl', {minimumFractionDigits: 2}) }} eur</b></h2>
        </vs-col>
      </vs-row>
    </div>
    <div v-if="financialReportsData && financialReportsData.length === 0">
      <vs-alert
        class="mt-5"
        color="danger"
        :title="'Ni zapisov'"
        active="true">
        Za izbran mesec in/ali enoto ni zapisov.
      </vs-alert>

    </div>


    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">


      </vs-sidebar>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import FinancialReportsTable from "../../components/Sopotniki/donations/FinancialReportsTable";
import {slSI} from "vuejs-datepicker/dist/locale";
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import xlsx from "json-as-xlsx";


export default {
  name: 'FinancialReports',

  components: {
    'v-select': vSelect,
    Datepicker,
    FinancialReportsTable
  },

  data() {
    return {
      selectedDate: new Date(),
      slSI: slSI,

      financialReportsData: null,
      balanceBefore: null,
      balanceAfter: null,
      unitsData: null,
      companyId: null,
      sidebarActive: false,
      unitId: -1,
      donationsTotal: 0,
      fields_to_export: {
        "Datum": {
          field: "date",
          callback: (value) => {
            return this.moment(value).subtract(3, 'hours').format('DD. MM. YYYY');
          },
        },
        "+ priliv / - odliv": {
          field: "direction",
          callback: (value) => {
            return value == 'DEPOSIT' ? '+ priliv' : '- odliv';
          },
        },
        "Znesek": {
          field: "amount",
          callback: (value) => {
            return Number(parseFloat(value)).toLocaleString('sl', {minimumFractionDigits: 2});
          },
        },
        "Opis": "description",
        "Enota": {
          field: "unit.name",
        },
        "Občina/-e": {
          field: "unit.coveredMunicipalities",
        },
      },
    }
  },

  computed: {
    xlsFooterData() {
      return [
        'Stanje gotovine začetek meseca: ' + this.balanceBefore + ' eur',
        'Stanje gotovine konec meseca: ' + this.balanceAfter + ' eur'
      ];
    }
  },

  async mounted() {
    const _this = this;
    this.selectedDate = await this.$store.getters.getSelectedDateForFinancialReports;

    _this.$vs.loading();

    let p1 = this.getUnits();

    Promise.all([p1]).then(() => {
      _this.$vs.loading.close();
      _this.getFinancialReports();
    });

  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;
    _this.companyId = null;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    onUnitChange() {
      const _this = this;

      _this.getFinancialReports(_this.unitId);
    },

    async getUnits() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
        .then((res) => {

          _this.unitsData = _.filter(res.data.data, {disabled: 0});
          _this.unitsData.push({
            id: -1,
            name: 'Vse enote',
            coveredMunicipalities: 'podatki za vse enote'
          })
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    getFinancialReports(unitId) {
      const _this = this;

      console.log("unitId", unitId);
      _this.$vs.loading();

      let currentUnit = null;
      if(_this.$globalFunctions.isAdmin()) {
        currentUnit = _this.unitId === -1 ? null : _this.unitId;
      } else {
        currentUnit = _this.$globalFunctions.getCurrentUnit().id;
      }

      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'financial-reporting', {
        params: {
          unit: currentUnit,
          startDate: _this.moment(_this.selectedDate).startOf('month').valueOf(),
          endDate: _this.moment(_this.selectedDate).endOf('month').valueOf()
        }
      })
        .then((res) => {
          _this.financialReportsData = res.data.data.financial_reports;
          _this.balanceBefore = res.data.data.balance_before, //parseFloat(res.data.data.balance_before).toFixed(2);
          _this.balanceAfter = res.data.data.balance_after, //parseFloat(res.data.data.balance_after).toFixed(2);

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    onDateChange() {
      this.$store.dispatch('setSelectedDateForFinancialReports', this.selectedDate);
      this.getFinancialReports();
    },

    customDateFormatter(date) {
      return this.moment(date).format('MMMM, YYYY');
    },

    addFinancialReport() {
      const _this = this;
      _this.$router.push({name: 'FinancialReportEdit'});
    },

    exportData() {
      const tableData = _.cloneDeep(this.financialReportsData);

      tableData.push({});

      tableData.push({
        direction: 'Stanje gotovine začetek meseca:',
        amount: this.balanceBefore
      });

      tableData.push({
        direction: 'Stanje gotovine konec meseca:',
        amount: this.balanceAfter
      });

      console.log("tabledata", tableData);

      let data = [
        {
          sheet: 'Gotovina',
          columns: [
            {
              label: 'Datum',
              value: (row) => (row.date ? this.moment(row.date).format('DD. MM. YYYY') || '' : ''),
            }, // Run functions
            {
              label: '+ priliv / - odliv',
              value: (row) => (row.direction ? row.direction === 'DEPOSIT' ? '+ priliv' : (row.direction === 'WITHDRAW' ? '- odliv' : row.direction) || '' : ''),
            }, // Run functions
            {label: 'Znesek', value: (row) => (row.amount ? Number(parseFloat(row.amount).toFixed(2)) || '' : '')}, // Run functions
            {label: 'Opis', value: (row) => (row.description ? row.description : '')}, // Run functions
            {label: 'Enota', value: (row) => (row.unit ? row.unit.name : '')}, // Run functions
            {label: 'Občina/-e', value: (row) => (row.unit ? row.unit.coveredMunicipalities || '' : '')}, // Run functions
          ],
          content: tableData,
        },
      ];
      let settings = {
        fileName: this.moment(this.selectedDate).year() + '_' + (this.moment(this.selectedDate).month()+1) + '_gotovina',
      };
      xlsx(data, settings);

    },

  }
}
</script>

